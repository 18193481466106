import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <h3>
                    Ju é todo fofo ♥
                </h3>
                <h3>
                    E um bruxinho gostoso ;)
                </h3>
                {/*<p>Ju é toda minha</p>*/}
            </header>
        </div>
    );
}

export default App;
